import React from 'react'
import { Box } from '@mui/material'

const Hero = () => {
  return (
    <Box 
      sx={{
        mb: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4
        },
        'img': {
          maxWidth: '100%' 
        } 
      }}>
      <picture>
        <source srcSet="images/cover_9000_naechte.jpg 1x, images/cover_9000_naechte@2x.jpg 2x, images/cover_9000_naechte@3x.jpg 3x"
          type="image/jpg" />
        <img src="images/cover_9000_naechte.jpg" alt="Cover 9000 Nächte" type="image/jpg" />
      </picture>
    </Box>
  )
}

export default Hero