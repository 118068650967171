import React from 'react'
import { Formik, Form } from 'formik'
import { Box, Button } from '@mui/material'
import * as Yup from 'yup'
import FormikField from './FormikField'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const validationSchema = Yup.object().shape({
  token: Yup.string()
})

const TokenForm = ({ formResponse }) => {

  const initialValues = {
    token: ''
  }

  async function onSubmit({ token }, { setSubmitting }) {
    setSubmitting(true)
    return await fetch(`${SERVER_URL}/api/token/validate-token`,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        formResponse({status: data.status, token: data.token})
      })
      .catch((err) => {
        console.log(err)
      })    
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        mx: 'auto'
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, errors, touched, isSubmitting }) => {
          return (
            <Form>
              <FormikField
                name='token'
                label='DOWNLOAD-CODE EINGEBEN'
                required
              />
              <Box
                pt={0}
                pb={2}
                mx='auto'
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  disabled={!dirty || !isValid || isSubmitting}
                  sx={{
                    textAlign: 'center',
                    flexBasis: '100%',
                    fontSize: '1rem',
                    py: 2,
                    '&.Mui-disabled': {
                      backgroundColor: 'secondary.dark',
                    }
                  }}
                >
                  {'Abschicken'}
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default TokenForm