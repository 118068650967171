import React from "react"
import { Box, TextField } from '@mui/material'
import { ErrorMessage, Field } from "formik"

const FormikField = ({ name, label, type = "text", fullwidth = true, required = false }) => {
  return (
    <Box
      sx={{
        mb: {
          xs: 1,
          md: 2,
          lg: 4
        },
      }}>
      <Field
        fullWidth={fullwidth}
        required={required}
        autoComplete="off"
        as={TextField}
        placeholder={label}
        name={name}
        type={type}
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        variant="outlined"
        helperText={<ErrorMessage name={name} />}
        sx={{
          'input': {
            textAlign: 'center'
          }
        }}
      />
    </Box>
  )
}

export default FormikField