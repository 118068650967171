import React from 'react'
import { Box, Container, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles'

const Layout = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box bgcolor={'primary.dark'} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

        <Container
          maxWidth='lg'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            py: 4
          }}
        >
          {children}
        </Container>
        <Box
          component="footer"
          textAlign={'center'}
          sx={{
            color: 'text.main',
            opacity: 0.5,
            fontSize: '0.75rem',
            pt: 1,
            pb: 2,
            '& a': {
              textDecoration: 'none',
              color: 'text.main'
            },
            '& a:hover': {
              color: 'text.main',
              opacity: 0.8,
            }
          }}
        >
          © {new Date().getFullYear()} &middot;
          {` `}
          <a href="https://17hippies.de">17 HIPPIES</a>
          {` `}
          &middot; {` `}
          <a href="https://17hippies.de/impressum" target="_blank" rel="noreferrer">Impressum</a>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Layout