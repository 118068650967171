import React, { useState, useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const Download = ({ token, status, validUntil, dlResponse }) => {
  const [validTime, setValidTime] = useState(null)

  useEffect(() => {
    let day = validUntil.getDate()
    let month = validUntil.getMonth() + 1
    let hours = validUntil.getHours()
    let minutes = validUntil.getMinutes()
    day = (day < 10) ? '0' + day.toString() : day.toString()
    month = (month < 10) ? '0' + month.toString() : month.toString()
    hours = (hours < 10) ? '0' + hours.toString() : hours.toString()
    minutes = (minutes < 10) ? '0' + minutes.toString() : minutes.toString()
    setValidTime(`${day}.${month} um ${hours}:${minutes}`)
  }, [validUntil])

  const handleDownloadFlac = () => {
    window.open(`${SERVER_URL}/api/download/token/flac/${token}`)
    dlResponse({ val: 'success' })
  }

  const handleDownloadM4a = () => {
    window.open(`${SERVER_URL}/api/download/token/m4a/${token}`)
    dlResponse({ val: 'success' })
  }

  return (
    <Box sx={{ maxWidth: '600px' }}>
      {status === 'valid' &&
        <Box sx={{ color: 'text.main', mb: 4 }}>
          <Typography variant='body1' gutterBottom>
            Klicke auf den Button und lade die ZIP-Datei mit dem Album herunter.
          </Typography>
          {validUntil &&
            <>
              <Typography variant='body1' gutterBottom >
                {'Sollte der Download nicht funktionieren, kannst du es innerhalb von einem Tag auf diesem oder einem anderen Gerät erneut probieren.'}
              </Typography>
              <Typography variant='body1' color={'secondary'} >
                {`Dein Download-Code ist bis zum ${validTime} Uhr gültig.`}
              </Typography>
            </>
          }
        </Box>
      }
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Button
          fullWidth
          color='secondary'
          variant='contained'
          onClick={handleDownloadFlac}
          sx={{
            mb: 2
          }}
        >
          Download Windows (FLAC - 675MB)
        </Button>
        <Button
          fullWidth
          color='secondary'
          variant='contained'
          onClick={handleDownloadM4a}
          sx={{
            // ml: 1
          }}
        >
          Download Mac (M4A - 682MB)
        </Button>
      </Box>
    </Box>
  )
}

export default Download