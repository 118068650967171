import {
  createTheme,
  responsiveFontSizes
} from '@mui/material/styles'

let theme = createTheme({
  typography: {
    fontFamily: `Metropolis, 'Helvetica Neue', sans-serif`
  },
  palette: {
    type: 'light',
    primary: {
      main: '#3F1731',
      light: '#723078',
      dark: '#27102A'
    },
    secondary: {
      main: '#E49f22',
      light: '#EDDA3F',
      dark: '#C47D27'
    },
    text: {
      main: '#FAEBCC'
    }
  }
})

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Impact, Arial, sans-serif',
          letterSpacing: '0.05em'
        },
        h2: {
          fontFamily: 'Impact, Arial, sans-serif',
          letterSpacing: '0.05em'
        },
        h3: {
          fontFamily: 'Impact, Arial, sans-serif',
          letterSpacing: '0.05em'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.text.main
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: theme.palette.text.main,
          '&.Mui-disabled': {
            color: `rgba(240, 240, 240, 0.5)`,
            backgroundColor: theme.palette.secondary.dark,
            boxShadow: 'inherit'
          }
        }
      }
    },
  }
})

theme = responsiveFontSizes(theme)

export default theme
