import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Layout from './components/Layout'
import Hero from './components/Hero'
import TokenForm from './components/TokenForm'
import Download from './components/Download'

function App() {
  const [dlToken, setDlToken] = useState('')
  const [tokenStatus, setTokenStatus] = useState('')
  const [responseText, setResponseText] = useState(null)
  const [validUntil, setValidUntil] = useState(null)

  const getValidTime = (redeemed) => {
    let redeemedDate = new Date(redeemed)
    redeemedDate.setDate(redeemedDate.getDate() + 1)
    setValidUntil(redeemedDate)
  }

  const handleFormResponse = ({ status, token }) => {
    setTokenStatus(status)
    if (status === 'valid') {
      setDlToken(token.dl_token)
      getValidTime(token.redeemed)
    }
  }

  useEffect(() => {
    let text = null
    switch (tokenStatus) {
      case 'missing':
        text = ['Der Download-Code ist unbekannt.']
        break

      case 'timeout':
        text = ['Der Download-Code ist abgelaufen.']
        break

      case 'nomoreattempts':
        text = ['Du hast die maximale Zahl an möglichen Downloadversuchen erreicht.']
        break

      case 'success':
        text = ['17 Tracks in exzellenter Audioqualität sind auf dem Weg zu dir. Habe etwas Geduld beim Download!', 'Sollte etwas schief gehen, lade die Seite neu und probiere es nochmal.']
        break

      default:
        break
    }
    setResponseText(text)
  }, [tokenStatus])

  const handleDlResponse = (res) => {
    if (res.val === 'success') {
      setTokenStatus('success')
    }
  }

  const RenderForm = () => {
    if (tokenStatus === 'valid' || tokenStatus === 'success') {
      return null
    }
    return (
      <TokenForm formResponse={handleFormResponse} />
    )
  }

  const RenderDownload = () => {
    let notValid = ['unknown', 'missing', 'timeout', 'nomoreattempts', 'success']
    if (!tokenStatus || notValid.indexOf(tokenStatus) >= 0) {
      return null
    }
    return (
      <Download token={dlToken} status={tokenStatus} validUntil={validUntil} dlResponse={handleDlResponse} />
    )
  }

  const RenderTextResponse = () => {
    if (!responseText) return null
    return (
      <Box sx={{ mb: 4 }}>
        {responseText && responseText.map((text, i) =>
          <Typography key={i} gutterBottom variant='body1' sx={{ color: 'secondary.main' }} >
            {text}
          </Typography>
        )}
      </Box>
    )
  }

  return (
    <Layout>
      <Box sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Hero />
          <Box component='header'
            sx={{
              mb: {
                xs: 2,
                sm: 3,
                md: 4
              },      
            }}
          >
            <Typography variant='h2' component='h1' color={'text.main'}>
              <Box component='span' sx={{ color: 'secondary.main' }}>ALBUM</Box> DOWNLOAD
            </Typography>
            <Typography variant='body1' sx={{ pt: { xs: 1, sm: 2 }, color: 'text.main' }}>
              {'Wir empfehlen, den Download auf einem Rechner/PC durchzuführen.'}
            </Typography>
          </Box>
          <RenderTextResponse />
          <RenderForm />
          <RenderDownload />
        </Box>
      </Box>
    </Layout>
  )
}

export default App
